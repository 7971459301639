/*Scroll up events*/
$('.scroll-up').click(function () {
    $('html, body').animate({
        scrollTop: 0
    }, 500);
});
$(window).scroll(function () {
    /*Scroll up appearance on scroll*/
    if ($(window).scrollTop() > $(window).height()) {
        $('.scroll-up').fadeIn();
    } else {
        $('.scroll-up').fadeOut();
    }
    /**/
});
/**/

/*Disable touch on fancybox modal*/
$(function () {
    $('[data-fancybox]').fancybox({
        touch: false
    });
});
/**/

/*Chosen-select init*/
function chosenInit() {
    if ($('.chosen').length) {
        var lang = document.getElementById('lang').value,
            resultMessage;
        if (lang == 'en') {
            resultMessage = 'No results found for'
        } else if (lang == 'kk') {
            resultMessage = 'Нәтижелер жоқ'
        } else {
            resultMessage = 'Ничего не найдено по запросу';
        }

        $(".chosen").chosen({
            search_contains: true,
            no_results_text: resultMessage
        });
    }
}

chosenInit();
/**/

/*Mobile dropdown*/
$('.mobile-dropdown__title').click(function () {
    var dropdownWrapper = $(this).closest('.mobile-dropdown');
    if (!dropdownWrapper.hasClass('opened')) {
        $('.mobile-dropdown.opened').removeClass('opened');
        dropdownWrapper.addClass('opened');
    } else {
        dropdownWrapper.removeClass('opened');
    }
});
$(document).click(function (event) {
    //Close profile context menu if anything except itself or profile link is clicked
    if (!$(event.target).closest('.mobile-dropdown').length) {
        if ($('.mobile-dropdown').hasClass('opened')) {
            $('.mobile-dropdown').removeClass('opened');
        }
    }
});
/**/

/*Menu-btn*/
$('.menu-btn-wrapper').click(function () {
    $(this).toggleClass('opened');
    $('.header-right').toggleClass('opened');
    if ($(this).hasClass('opened')) {
        bodyUnscrollable();
    } else {
        bodyScrollable();
    }
});
/**/

//Get scrollbar width
function getScrollbarWidth() {
    var outer = document.createElement("div");
    outer.style.visibility = "hidden";
    outer.style.width = "100px";
    outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps

    document.body.appendChild(outer);

    var widthNoScroll = outer.offsetWidth;
    // force scrollbars
    outer.style.overflow = "scroll";

    // add innerdiv
    var inner = document.createElement("div");
    inner.style.width = "100%";
    outer.appendChild(inner);

    var widthWithScroll = inner.offsetWidth;

    // remove divs
    outer.parentNode.removeChild(outer);

    return widthNoScroll - widthWithScroll;
}

//Make body unscrollable
function bodyUnscrollable() {
    if (!$('body').hasClass('no-scroll')) {
        var scrollTop = $(window).scrollTop();
        //Detect all fixed elements on the page
        var find = $('*').filter(function () {
            return $(this).css('position') == 'fixed';
        });
        $('.main-wrapper').css('margin-top', -scrollTop);
        $('body').addClass('no-scroll').css('margin-right', getScrollbarWidth());
        find.css('margin-right', getScrollbarWidth());
    }
}

//Make body scrollable
function bodyScrollable() {
    if ($('body').hasClass('no-scroll')) {
        var scrollTop = $('.main-wrapper').css('margin-top').slice(0, -2);
        //Detect all fixed elements on the page
        var find = $('*').filter(function () {
            return $(this).css('position') == 'fixed';
        });
        $('body').removeClass('no-scroll').css('margin-right', 0);
        find.css('margin-right', 0);
        $('.main-wrapper').css('margin-top', 0);
        $(window).scrollTop(-scrollTop);
    }
}

/*Spoilers*/
$('.spoiler__title').click(function () {
    //independent spoilers
    if ($(this).closest('.spoilers').hasClass('spoilers--independent')) {
        $(this).siblings('.spoiler__desc').slideToggle(300).closest('.spoiler').toggleClass('active');
    }
    //dependent spoilers
    else {
        if ($(this).siblings('.spoiler__desc').is(':visible')) {
            $(this).siblings('.spoiler__desc').slideUp(300).closest('.spoiler').removeClass('active');
        } else {
            $(this).closest('.spoilers').find('.spoiler__desc:visible').slideUp(300).closest('.spoiler').removeClass('active');
            $(this).siblings('.spoiler__desc').slideDown(300).closest('.spoiler').addClass('active');
        }
    }
});
/**/

/*Menu dropdown*/
$('.menu li.dropdown a').click(function () {
    if ($(window).width() < 767) {
        $(this).siblings('ul').toggle();
        $(this).toggleClass('opened');
    }
});